<template>
    <el-row :gutter="160">
        <el-col :span="2">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd">添加合服任务</el-button>
        </el-col>
        <el-col :span="24">
            <el-table ref="multipleTable" :data="mergeList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="name" label="servername" show-overflow-tooltip></el-table-column>
                <el-table-column prop="title" label="合服名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="host" label="IP地址" show-overflow-tooltip></el-table-column>
                <el-table-column prop="auth_port" label="认证端口" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="game_port" label="游戏端口" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column label="排队服务器" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{scope.row.queue_host}} : {{scope.row.queue_port}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="区服数量" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{scope.row.servers.length}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{mergeState(scope.row.state)}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="运行" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.online===0" style="color: #FF0000;">离线</span>
                        <span v-if="scope.row.online===1" style="color: #00D000;">在线</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="280" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEdit(scope.row)" type="primary" icon="el-icon-edit"
                            size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickAppend(scope.row)" type="success"
                            icon="el-icon-circle-plus-outline" size="small" v-if="false">追加</el-button>
                        <el-button @click.native.prevent="onClickLog(scope.row.id)" type="warning"
                            icon="el-icon-notebook-1" size="small">日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogForm" width="32%" :close-on-click-modal="false" center
            class="formparams">
            <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item label="目标合服">
                    <el-select v-model="form.id" placeholder="选择目标" style="width: 100%;">
                        <el-option v-for="item in waitingList" :key="item.id" :label="item.name+'('+item.title+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择合并的区服">
                    <el-button type="primary" icon="el-icon-menu" @click="onSelectItem">区服</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddMergeServer">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========区服弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogFormModify" :close-on-click-modal="false" width="900px"
            center>
            <el-form :model="form" label-width="116px">
                <el-form-item label="【服务器名】">
                    <el-row>
                        <el-col :span="12">                
                            <el-form-item label="name" label-width="60px">
                                <el-input v-model="form.name" placeholder="内部标识名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="显示" label-width="60px">
                                <el-input v-model="form.title" placeholder="显示用名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>                
                <el-form-item label="【区服IP地址】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="外网" label-width="60px">
                                <el-input v-model="form.host" placeholder="区服外网IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="内网" label-width="60px">
                                <el-input v-model="form.lanhost" placeholder="区服内网IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【区服端口】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="认证" label-width="60px">
                                <el-input v-model="form.auth_port" placeholder="认证端口"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="游戏" label-width="60px">
                                <el-input v-model="form.game_port" placeholder="游戏端口"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【排队服务器】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="外网" label-width="60px">
                                <el-input v-model="form.queue_host" placeholder="排队服IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="端口" label-width="60px">
                                <el-input v-model="form.queue_port" placeholder="排队服端口"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>               
                <el-form-item label="【时间设置】">
                    <el-row>
                        <el-col :span="8">                    
                            <el-form-item label="时区" label-width="50px">
                                <el-input v-model="form.timezone" placeholder="默认东8区"></el-input>
                            </el-form-item>   
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="开服" label-width="50px">
                                <el-date-picker
                                  v-model="form.open_time"
                                  type="datetime"
                                  placeholder="开服时间"
                                  style="width: 100%;">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="当前" label-width="50px">
                                <el-date-picker
                                  v-model="form.server_time"
                                  type="datetime"
                                  placeholder="服务器时间"
                                  @change="onServerTimeChanged"
                                  style="width: 100%;">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【区服类型】">
                    <el-row>
                        <el-col :span="24">        
                            <el-form-item label="设置" label-width="50px">
                                <el-select v-model="form.istest" placeholder="请选择是否正式服" style="width: 100%;">
                                    <el-option v-for="item in serverTypes" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>  
                        </el-col>
                    </el-row>
                </el-form-item>  
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitUpdateServer">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogFormModify = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========区服弹框END====== -->
        <!-- ========物品弹框====== -->
        <el-dialog :title="titleSelectServer" :visible.sync="dialogItemForm" width="70%" :close-on-click-modal="false"
            :show-close="false" center class="itemdialog">
            <div style="width: 100%">
                <el-row>
                    <el-col :span="4">
                        <el-select v-model="form.group" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup"
                            style="width:100%;">
                            <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;
                        </el-button>
                    </el-col>
                    <el-col :span="10">
                        <el-button type="primary" style="width:100%;" disabled>已选择的服务器</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="0">
                    <el-col :span="10">
                        <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist"
                            @selection-change="leftSelectionChange">
                            <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                            <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" @click="onAddItem([scope.row])"
                                        :disabled="editMergeParams&&!appendMergeServer">添加</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <el-row>
                            <el-button size="mini" type="primary" @click="onAddItem(null)" style="margin-top:180px;"
                                :disabled="editMergeParams&&!appendMergeServer">批量&gt;&gt;添加</el-button>
                        </el-row>
                        <el-row>
                            <el-button size="mini" type="danger" @click="onRemoveItem(null)"
                                :disabled="editMergeParams">批量&lt;&lt;移除</el-button>
                        </el-row>
                    </el-col>
                    <el-col :span="10">
                        <el-table :data="selectedServers" border style="width: 100%" height="420" class="serverlist"
                            @selection-change="rightSelectionChange">
                            <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                            <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>
                            <el-table-column label="主区服" :key="updateDetailRow">
                              <template slot-scope="scope">
                                <el-switch
                                  v-model="scope.row.default"
                                  active-color="#13ce66"
                                  inactive-color="#9999a1"
                                  @change="onDefaultServerChanged(scope.row)">
                                </el-switch>
                              </template>
                            </el-table-column> 
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="danger" @click="onRemoveItem([scope.row])"
                                        style="float:right;" :disabled="editMergeParams">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onFinishServerSelect"
                    style="margin-right: 16px;">确 定</el-button>
                <el-button type="primary" icon="el-icon-close" @click="dialogItemForm=false" plain
                    style="margin-left: 16px;">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========日志弹框BEGIN====== -->
        <el-dialog title="合服日志&状态" :visible.sync="dialogLogForm" @close="onCloseLog" width="60%"
            :close-on-click-modal="false" center class="itemdialog">
            <el-table :data="mergeLog" height="600" style="width: 100%" :cell-style="{padding:4+'px'}"
                class="serverlist" ref="dialogTable" :row-class-name="logRowClassName">
                <el-table-column prop="id" label="id" width="100"></el-table-column>
                <el-table-column prop="create_time" label="时间" width="200"></el-table-column>
                <el-table-column prop="content" label="信息" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- ========日志弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        data() {
            return {
                waitingList:[], // 待合服的服务器列表
                mergeList: [], // 全部合服的服务器列表
                formTitle: '',
                titleSelectServer: '',
                dialogForm: false,
                dialogFormModify: false,
                dialogItemForm: false,
                dialogLogForm: false,
                form: {
                    id: '',
                    name: '',
                    title: '',
                    host: '',
                    lanhost: '',
                    auth_port: '',
                    game_port: '',
                    queue_host: '',
                    queue_port: '',
                    timezone: '',
                    opents: '',
                    open_time:'', // 显示用
                    offset: '',
                    server_time:'',// 显示用
                    istest:0, // 测试服与否：0默认正式，1测试服
                },
                search: '',
                servers: [], // 可选的区服
                selectedServers: [], // 已选择的区服
                leftSelectRows: [], // 左边选择的项
                rightSelectRows: [], // 右边选择的项
                serverGroups: [],
                editMergeParams: false, // 标识编辑合服参数(无法修改区服项)
                appendMergeServer: 0, // 需追加区服的合服id
                appendServerids: [], // 追加的区服id表
                mergeLog: [], // 日志数据
                lastMergeLogId: 0, // 最新一条日志
                logTimer: null,
                updateDetailRow: 0,
                formLabelWidth: '130px',
                serverTypes:[{value:0,label:'正式服',tag:'succes'},{value:1,label:'测试服',tag:'primary'}],
                tableHeight: document.documentElement.clientHeight-136,
            }
        },
        created: function() {
            this.refreshMergeList()
            this.refreshGroupList()
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 物品关键词过滤显示
            filterItems() {
                return this.servers.filter((data) => {
                    // 已选择过的则不用再显示了
                    for (let i = 0; i < this.selectedServers.length; i++) {
                        if (data.id == this.selectedServers[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.name.includes(this.search) || data.title.includes(this
                    .search));
                })
            },
        },
        methods: {
            // 刷新列表
            refreshMergeList() {
                this.gameHttp(1021, {}).then((response) => {
                    this.mergeList = response.data.list;
                    this.waitingList = [];
                    for (let i=0; i<this.mergeList.length; i++) {
                        if (-1 == this.mergeList[i].state) {
                            this.waitingList.push({
                                id : this.mergeList[i].id,
                                name : this.mergeList[i].name,
                                title : this.mergeList[i].title,
                            });
                        }
                    }
                })
            },
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 刷新日志
            refreshMergeLog(id) {
                this.gameHttp(1024, {
                    merid: id,
                    lastid: this.lastMergeLogId,
                }).then((response) => {
                    if (0 == response.data.errcode) {
                        let len = response.data.list.length;
                        if (0 == len) {
                            return;
                        }
                        this.lastMergeLogId = response.data.list[len - 1].id;
                        for (let i = 0; i < len; i++) {
                            this.mergeLog.push(response.data.list[i]);
                        }
                        // 滚动到底部
                        setTimeout(()=>{
                            this.$refs.dialogTable.bodyWrapper.scrollTop = this.$refs.dialogTable.bodyWrapper.scrollHeight;                          
                        }, 10);
                    }
                })
            },
            // 关闭日志
            onCloseLog() {
                clearInterval(this.logTimer);
                this.refreshMergeList();
            },
            // 弹出新增编辑框
            onClickAdd() {
                this.editMergeParams = false;
                this.appendMergeServer = 0;
                this.formTitle = '新增合服任务'
                this.dialogForm = true
                this.servers = []
                this.selectedServers = []
                this.leftSelectRows = []
                this.rightSelectRows = []
                for (let k in this.form) {
                    this.form[k] = ''
                }
            },
            // 弹出修改编辑框
            onClickEdit(row) {
                this.editMergeParams = true;
                this.appendMergeServer = 0;
                this.formTitle = '修改合服参数'
                this.dialogFormModify = true
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row))
                if (row.open_time) {
                    this.form.open_time = new Date( row.open_time );
                }
                if (row.server_time) {
                    this.form.server_time = new Date( row.server_time )
                }
                if (row.preopentime) {
                    this.form.preopentime = new Date( row.preopentime )
                }
                delete this.form.update_time // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                delete this.form.state // 忽略不需要提交的字段
                delete this.form.servers // 忽略不需要提交的字段
            },
            // 服务器时间修改
            onServerTimeChanged() {
                this.serverTimeChanged = true;
            },
            // 弹出追加区服
            onClickAppend(row) {
                this.titleSelectServer = "选择要追加的区服";
                this.appendMergeServer = row.id;
                this.appendServerids = [];
                this.editMergeParams = true;
                this.dialogItemForm = true
                this.servers = []
                this.selectedServers = JSON.parse(JSON.stringify(row.servers))
                this.leftSelectRows = []
                this.rightSelectRows = []
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row))
                delete this.form.update_time // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                delete this.form.state // 忽略不需要提交的字段
            },
            // 查看合服日志
            onClickLog(merid) {
                this.lastMergeLogId = 0;
                this.mergeLog = [];
                this.refreshMergeLog(merid);
                this.dialogLogForm = true;
                // 定时刷新日志
                this.logTimer = setInterval(() => {
                    this.refreshMergeLog(merid);
                }, 500);
            },
            // 弹出选择服务器
            onSelectItem() {
                this.dialogItemForm = true
                this.titleSelectServer = "选择要合并的区服";
            },
            // 表单选择分组
            onFormSelectGroup(id) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: id,
                    avlmer: 1,
                }).then((response) => {
                    this.servers = response.data.list
                })
            },
            // 完成区服的选择
            onFinishServerSelect() {
                if (this.appendMergeServer) { // 追加区服判断
                    // 区服id表
                    if (0 == this.appendServerids.length) {
                        this.$message.error('请指定需要追加到合服的区服');
                        return;
                    }
                    // 请求追加
                    let rqs = {
                        id: this.appendMergeServer,
                        serverids: this.appendServerids
                    };
                    this.gameHttp(1023, rqs).then((response) => {
                        if (0 == response.data.errcode) {
                            this.refreshMergeList()
                            this.$message.success("提交成功")
                             // 新增合服成功时弹出日志状态
                            this.onClickLog(this.appendMergeServer);
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }
                this.dialogItemForm = false;
            },
            // 处理左边多选变化
            leftSelectionChange(rows) {
                this.leftSelectRows = rows;
            },
            // 处理右边多选变化
            rightSelectionChange(rows) {
                this.rightSelectRows = rows;
            },
            // 添加区服到选择列
            onAddItem(rows) {
                if (!rows) {
                    rows = this.leftSelectRows;
                }
                for (let i = 0; i < rows.length; i++) {
                    rows[i].default = false; // 默认不是主区服
                    this.selectedServers.push(rows[i]);
                    if (this.appendMergeServer) { // 追加模式提取追加的id
                        this.appendServerids.push(rows[i].id);
                    }
                }
            },
            // 移除选择的区服
            onRemoveItem(rows) {
                if (!rows) {
                    rows = this.rightSelectRows;
                }
                let funcRomove = (row) => {
                    for (let i = 0; i < this.selectedServers.length; i++) {
                        if (row.id == this.selectedServers[i].id) {
                            this.selectedServers.splice(i, 1);
                            // 左边列表不存在的则压入到左边
                            for (let j = 0; j < this.servers.length; j++) {
                                if (row.id == this.servers[j].id) {
                                    return;
                                }
                            }
                            this.servers.push(row);
                            break;
                        }
                    }
                };
                for (let r = 0; r < rows.length; r++) {
                    funcRomove(rows[r]);
                }
            },
            // 选择默认服
            onDefaultServerChanged(row) {
                if (row.default) {
                    for (let i in this.selectedServers) {
                        if (row != this.selectedServers[i]) {
                            this.selectedServers[i].default = false;
                        }
                    }
                }
                this.updateDetailRow ++; // 不加这个，这它不更新选择
            },
            // 添加合服
            onAddMergeServer() {
                if (!this.form.id) {
                    this.$message.error('请指定目标'); return
                }
                let main_id = 0;
                // 区服id表
                let serverids = [];
                for (let i = 0; i < this.selectedServers.length; i++) {
                    serverids.push(this.selectedServers[i].id);
                    if (this.selectedServers[i].default) { // 主区服id
                        main_id = this.selectedServers[i].id;
                    }
                }
                if (0 == serverids.length) {
                    this.$message.error('需指定要合并的区服'); return;
                }
                if (0 == main_id) {
                    this.$message.error('请指定主区服'); return;
                }
                this.dialogForm = false
                // 请求操作                      
                let rqs = JSON.parse(JSON.stringify(this.form));
                rqs.serverids = serverids;
                rqs.main_id = main_id;
                this.gameHttp(1022, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshMergeList()
                        if (response.data.merid) { // 新增合服成功时弹出日志状态
                            this.onClickLog(response.data.merid);
                        }
                        this.$message.success("提交成功")
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 提交编辑区服
            onCommitUpdateServer() {
                for (var key in this.form) {
                    if ('id'==key || 'timezone'==key || 'opents'==key || 'offset'==key || 'open_time'==key 
                    || 'server_time'==key || 'preopentime'==key || 'preopentime'==key|| 'regtimes'==key) { continue; } // 忽略可空的字段
                    if ( 0 == this.form[key].length ) {
                        this.$message.error(key + ' 参数不能为空，请检查确认！');
                        return;
                    }
                }
                this.dialogFormModify = false
                // 开服时间&服务器偏移时间转换
                this.form.opents = this.form.open_time ?  Date.parse(this.form.open_time)/1000 : 0;
                this.form.preopentime = 'NULL';
                if (this.serverTimeChanged) { // 无修改的就不用动，否则
                    this.form.offset = this.form.server_time ? Date.parse(this.form.server_time)/1000 : 0;
                    if (this.form.offset) {
                        this.form.offset -=  parseInt(Date.now()/1000);
                    }
                }
                console.log(this.form);
                let rqs = JSON.parse( JSON.stringify(this.form) );
                rqs.merid = this.form.id; // 标识为是合服修改
                // 开放注册时间
                rqs.regtimes = [];
                // 请求操作
                this.gameHttp(1002, rqs).then((response) => {
                    this.$message.success(response.data.errmsg)
                    this.refreshMergeList()
                })
            },
            // 合服状态显示
            mergeState(state) {
                let states = {"-1":"待用", "0":"操作中", "1":"已完成"};
                return states[state];
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
            // 日志表标红
            logRowClassName({
                row
            }) {
                if (-1 == row.state) {
                    return 'error-row';
                } else if (1 == row.state) {
                    return 'warnlog-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }
    
    .serverlist.el-table .error-row {
        color: #ff0000;
        font-weight: bold;
    }   
    .serverlist.el-table .warnlog-row {
        color: #ffaa00;
        font-weight: bold;
    }       

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
</style>
